import React from 'react';
import { crossIcon, eyeIcon, reportFakeIcon } from '../../../../../assets/svg';
import Svg from '../../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const FundingListItem = ({
    Symbol,
    item,
    onSendReportMessage,
    AskPrice,
    AskTax,
    AskAmount,
    AskAmountUSDT,
    Ex1,
    BidPrice,
    BidTax,
    BidAmount,
    BidAmountUSDT,
    Ex2,
    Spread,
    Profit,
    ProfitPct,
    SpotFee,
}) => {
    return (
        <div className={styles['fundingListItem']}>
            <div className={styles['fundingListItem__header']}>
                <h2 className={styles['fundingListItem__title']}>
                    {Symbol.toUpperCase().replace('USDT', '')}
                </h2>

                <div className={styles['fundingListItem__headerBtns']}>
                    <button
                        className={styles['fundingListItem__hiderBtn']}
                        onClick={() => onSendReportMessage(item)}
                    >
                        <Svg
                            id={reportFakeIcon}
                            className={styles['fundingListItem__reportIcon']}
                        />
                    </button>
                    <button
                        onClick={() => {
                            console.log('add to hidden');
                        }}
                        className={styles['fundingListItem__hiderBtn']}
                    >
                        <Svg
                            className={styles['fundingListItem__hideIcon']}
                            id={eyeIcon}
                        />
                    </button>
                    <button
                        className={styles['fundingListItem__hiderBtn']}
                        onClick={() => {
                            console.log('add to blacklist');
                        }}
                    >
                        <Svg
                            className={styles['fundingListItem__crossIcon']}
                            id={crossIcon}
                        />
                    </button>
                </div>
            </div>
            <div
                className={`${styles['fundingListItem__resultBlock']} ${styles['fundingListItem__resultBlock_output']}`}
            >
                {AskAmount && (
                    <p className={styles['fundingListItem__resultBlockText']}>
                        <span
                            className={
                                styles['fundingListItem__resultBlockText_green']
                            }
                        >
                            BUY:{' '}
                        </span>
                        {AskAmount.toFixed(4)}
                        {AskAmountUSDT && `(${AskAmountUSDT.toFixed(4)}) USDT`}
                    </p>
                )}
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        ASK:{' '}
                    </span>
                    {AskPrice}
                </p>

                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        Комиссия:{' '}
                    </span>
                    {AskTax.toFixed(4)}$
                </p>

                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        Купить:{' '}
                    </span>
                    {/* <a
                        className={
                            styles['fundingListItem__resultBlockLinkText']
                        }
                        target={'_blank'}
                        rel="noreferrer"
                        href={linkAsk}
                    >
                        {formatExchangeTitle(exAsk)}
                    </a> */}
                    {Ex1}
                </p>
            </div>
            <div
                className={`${styles['fundingListItem__resultBlock']} ${styles['fundingListItem__resultBlock_input']}`}
            >
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        SELL:{' '}
                    </span>
                    {BidAmount.toFixed(4)}{' '}
                    {`(${BidAmountUSDT && BidAmountUSDT.toFixed(4)} USDT)`}
                </p>
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        BID:{' '}
                    </span>
                    {BidPrice}
                </p>
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        Комиссия:{' '}
                    </span>
                    {BidTax.toFixed(4)}$
                </p>
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        Продать:{' '}
                    </span>
                    {/* <a
                        className={
                            styles['fundingListItem__resultBlockLinkText']
                        }
                        target={'_blank'}
                        rel="noreferrer"
                        href={linkBid}
                    >
                        {formatExchangeTitle(exBid)}
                    </a> */}
                    {Ex2}
                </p>
            </div>
            <p className={styles['fundingListItem__profitText']}>
                Общая комиссия:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {SpotFee.toFixed(4)}$
                </span>
            </p>
            <p className={styles['fundingListItem__profitText']}>
                Спред входа:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {Spread.toFixed(4)}
                </span>
            </p>
            <p className={styles['fundingListItem__profitText']}>
                Profit:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {Profit.toFixed(4)}$ ({ProfitPct.toFixed(4)}%)
                </span>
            </p>
        </div>
    );
};

export default FundingListItem;
