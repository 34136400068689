import React, { memo } from 'react';
import {
    blacklistIcon,
    eyeIcon,
    filtersIcon,
    refreshIcon,
} from '../../../../assets/svg';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const OptionsBlock = memo(
    ({
        openBlackListModal,
        openFilterModal,
        openHiddenItemsModal,
        toggleAutoRefresh,
        autoRefresh,
        isSubscribed,
    }) => {
        return (
            <div className={styles['optionsBlock']}>
                <button
                    className={styles['optionsBlock__btn']}
                    onClick={openBlackListModal}
                >
                    Blacklist
                    <Svg
                        className={styles['optionsBlock__icon']}
                        id={blacklistIcon}
                    />
                </button>

                {isSubscribed && (
                    <button
                        onClick={openFilterModal}
                        className={styles['optionsBlock__btn']}
                    >
                        Filters
                        <Svg
                            className={styles['optionsBlock__icon']}
                            id={filtersIcon}
                        />
                    </button>
                )}

                <button
                    className={`${styles['optionsBlock__btn']} ${!autoRefresh ? styles['optionsBlock__btn_disabled'] : ''}`}
                    onClick={toggleAutoRefresh}
                >
                    Refresh
                    <Svg
                        className={styles['optionsBlock__icon']}
                        id={refreshIcon}
                    />
                </button>

                <button
                    className={`${styles['optionsBlock__btn']} ${styles['optionsBlock__eyeBtn_desk']}`}
                    onClick={openHiddenItemsModal}
                >
                    Hidden
                    <Svg
                        className={styles['optionsBlock__icon']}
                        id={eyeIcon}
                    />
                </button>
            </div>
        );
    }
);

export default OptionsBlock;
