import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import MainPageArbitrageBot from '../../components/MainPage/MainPageArbitrageBot/MainPageArbitrageBot';
import MainPageArbitrageNews from '../../components/MainPage/MainPageArbitrageNews/MainPageArbitrageNews';
import MainPageArbitrageScanner from '../../components/MainPage/MainPageArbitrageScanner/MainPageArbitrageScanner';
import MainPageCommunity from '../../components/MainPage/MainPageCommunity/MainPageCommunity';
import MainPageHeader from '../../components/MainPage/MainPageHeader/MainPageHeader';
import MainPagePricing from '../../components/MainPage/MainPagePricing/MainPagePricing';
import MainPageRegisterPopup from '../../components/MainPage/MainPageRegisterPopup/MainPageRegisterPopup';
import MainPageSignin from '../../components/MainPage/MainPageSignin/MainPageSignin';

import styles from './MainPage.module.scss';

function MainPage() {
    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={styles['main']}>
                <MainPageHeader />
                <div className={'container'}>
                    <MainPageArbitrageBot />
                    <MainPageArbitrageScanner />
                    <MainPageArbitrageNews />
                    <MainPageCommunity />
                    <MainPagePricing />
                </div>
                <MainPageSignin />
                <MainPageRegisterPopup />
            </div>
        </Scrollbar>
    );
}

export default MainPage;
