import React, { memo, useState } from 'react';
import { useFormValue } from '../../../../hooks/useFormValue';
import MainBtn from '../../../layout/MainBtn/MainBtn';
import MainInput from '../../../layout/MainInput/MainInput';

import Svg from '../../../layout/Svg/Svg';
import styles from './SettingsForm.module.scss';

const SettingsForm = memo(
    ({
        fields,
        onSubmit,
        loading,
        error,
        submitSuccessText,
        buttons,
        readOnlyFields,
    }) => {
        const initialData = fields.reduce((acc, cur) => {
            acc[cur.key] = cur.initialValue || '';
            return acc;
        }, {});
        const { formData, onChange } = useFormValue(initialData);
        const [submitSuccess, setSubmitSuccess] = useState(false);

        let errorText = '';

        if (error && typeof error === 'string') {
            const errorField = fields.find((item) => error.includes(item.key));
            errorText = errorField
                ? error.replace(errorField.key, errorField.placeholder)
                : error;
        }

        if (error?.message) {
            const errorField = fields.find((item) =>
                error.message.includes(item.key)
            );
            errorText = errorField
                ? error.message.replace(errorField.key, errorField.placeholder)
                : error;
        }

        const onSubmitForm = (e) => {
            e.preventDefault();
            const onSuccess = () => {
                setSubmitSuccess(true);
                setTimeout(() => setSubmitSuccess(false), 3000);
            };
            onSubmit(formData, onSuccess);
        };

        return (
            <form
                method="POST"
                className={styles['settingsForm']}
                onSubmit={onSubmitForm}
            >
                {fields.map(({ icon, placeholder, key, type }, index) => (
                    <MainInput
                        disabled={loading}
                        key={index}
                        icon={icon}
                        value={formData[key]}
                        name={key}
                        onChange={onChange}
                        type={type || 'text'}
                        placeholder={placeholder}
                        className={styles['settingsForm__input']}
                    />
                ))}
                <p
                    className={`${styles['settingsForm__notText']} ${styles['settingsForm__notText_fail']} ${error?.length > 0 && Boolean(error) && styles['settingsForm__notText_active']}`}
                >
                    {errorText}
                </p>
                <p
                    className={`${styles['settingsForm__notText']} ${styles['settingsForm__notText_success']} ${submitSuccess && styles['settingsForm__notText_active']}`}
                >
                    {submitSuccessText}
                </p>
                {readOnlyFields &&
                    readOnlyFields.map(
                        ({ icon, fieldLable, fieldValue }, index) => (
                            <div
                                key={index}
                                className={
                                    styles['settingsForm__readOnlyField']
                                }
                            >
                                <Svg id={icon} />
                                <span>{`${fieldLable}: ${fieldValue}`}</span>
                            </div>
                        )
                    )}
                {buttons &&
                    buttons.map((button, index) => (
                        <MainBtn
                            className={styles['settingsForm__btn']}
                            key={index}
                            onClick={button.onClick}
                            type="button"
                        >
                            {button.text}
                        </MainBtn>
                    ))}
                <MainBtn
                    disabled={loading}
                    type={'submit'}
                    className={styles['settingsForm__btn']}
                >
                    Сохранить
                </MainBtn>
            </form>
        );
    }
);

export default SettingsForm;
