import {
    arbitrageIcon,
    closedArbitrageIcon,
    funding,
    newsIcon,
    outflowIcon,
    settingsIcon,
    subscriptionIcon,
    supportIcon,
    telegramIcon,
    trackerLink,
} from '../../../../assets/svg';
import {
    arbitrageTypes,
    newsTypes,
    telegramLink,
    trackerTypes,
} from '../../../../constants';
import {
    adminNewsPagePath,
    arbitragePagePath,
    cexOutflowPagePath,
    closedArbitragePagePath,
    fundingPagePath,
    newsPagePath,
    settingsPagePath,
    subscribtionPagePath,
    supportPagePath,
    trackerPagePath,
} from '../../../../router/path';

export const navLinks = [
    {
        title: 'Arbitration News',
        icon: newsIcon,
        path: newsPagePath,
        adminPath: adminNewsPagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'Актуальные новости',
                href: newsTypes.actuals,
                isPrivate: true,
            },
            {
                title: 'Прошедшие новости',
                href: newsTypes.latest,
                isPrivate: true,
            },
            {
                title: 'News Free',
                href: newsTypes.free,
            },
        ],
    },
    {
        title: 'Arbitrage',
        icon: arbitrageIcon,
        path: arbitragePagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'CEX — CEX Arbitrage',
                href: arbitrageTypes.cexToCex,
                isPrivate: true,
            },
            {
                title: 'CEX — DEX Arbitrage',
                href: arbitrageTypes.cexToDex,
                isPrivate: true,
            },
            {
                title: 'DEX — DEX Arbitrage',
                href: arbitrageTypes.dexToDex,
                isPrivate: true,
            },
            {
                title: 'Arbitrage Free',
                href: arbitrageTypes.free,
            },
        ],
    },
    {
        title: 'Closed Arbitrage',
        icon: closedArbitrageIcon,
        path: closedArbitragePagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'CEX — CEX Closed Arbitrage',
                href: arbitrageTypes.cexToCex,
                isPrivate: true,
            },
            {
                title: 'CEX — DEX Closed Arbitrage',
                href: arbitrageTypes.cexToDex,
                isPrivate: true,
            },
            {
                title: 'Closed Arbitrage Free',
                href: arbitrageTypes.free,
            },
        ],
    },
    {
        title: 'Tracker',
        icon: trackerLink,
        path: trackerPagePath,
        isPrivate: true,
        sublinks: [
            { title: 'CEX Tracker', href: trackerTypes.CEX },
            { title: 'DEX Tracker', href: trackerTypes.DEX, isPrivate: true },
        ],
    },
    {
        title: 'CEX Outflows',
        icon: outflowIcon,
        path: cexOutflowPagePath,
        isPrivate: true,
    },
    {
        title: 'Funding',
        icon: funding,
        path: fundingPagePath,
        isPrivate: true,
    },
    {
        title: 'Subscription',
        icon: subscriptionIcon,
        path: subscribtionPagePath,
    },
    {
        title: 'Settings',
        icon: settingsIcon,
        path: settingsPagePath,
        isPrivate: true,
    },
    {
        title: 'Support',
        icon: supportIcon,
        path: supportPagePath,
    },
];

export const socials = [{ link: telegramLink, iconId: telegramIcon }];
