export const exchangeColors = [
    {
        ex: 'defilamaEth',
        color: '#8991b3',
        name: 'Ethereum',
        id: 1,
    },
    {
        ex: 'defilamaPolygon',
        color: '#843FE1',
        name: 'Polygon',
        id: 137,
    },
    {
        ex: 'defilamaBsc',
        color: '#ffc728',
        colorText: '#100c08',
        name: 'BSC',
        id: 56,
    },
    {
        ex: 'defilamaArbitrum',
        color: '#fff',
        colorText: '#5871F2',
        name: 'Arbitrum',
        id: 42161,
    },
    {
        ex: 'jupiter',
        color: '#14F195',
        colorText: '#100c08',
        name: 'Solana',
        id: 42161,
    },
    {
        ex: '1inchBase',
        color: '#0155fc',
        colorText: '#ffffff',
        name: 'Base',
        id: 8543,
    },
    {
        ex: 'eth',
        color: '#8991b3',
        name: 'Ethereum',
        id: 1,
    },
    {
        ex: 'defilamaBase',
        color: '#0155fc',
        colorText: '#ffffff',
        name: 'Base',
        id: 8543,
    },
    {
        ex: 'stonfi',
        color: '#0098ea',
        name: 'Ton',
        id: 19,
    },
    {
        ex: 'dedust',
        color: '#0098ea',
        name: 'Ton',
        id: 20,
    },
];

export const formatExchangeTitle = (exchangeTitile) => {
    if (
        exchangeTitile.startsWith('defilama') ||
        exchangeTitile.startsWith('jupiter') ||
        exchangeTitile.startsWith('1inch') ||
        exchangeTitile.startsWith('stonfi') ||
        exchangeTitile.startsWith('dedust')
    )
        return 'SWAP';
    else if (exchangeTitile.toLowerCase() === 'okex') return 'OKX';
    else return exchangeTitile.toUpperCase();
};

export const setOrderText = (amount) => {
    const types = [
        [[1], 'ордер'],
        [[2, 3, 4], 'ордера'],
    ];
    const type = types.find((item) => item[0].includes(amount));
    return type ? type[1] : 'ордеров';
};

export const openNewBrowserWindow = (
    spendingTokenAddress,
    spendingChainId,
    receivingTokenAddress,
    receivingChainId,
    AskAmount
) => {
    const url = `https://lifi.1whale.io/?fromChain=${spendingChainId}&toChain=${receivingChainId}&fromToken=${spendingTokenAddress}&toToken=${receivingTokenAddress}&amount=${AskAmount}`;
    window.open(url, '_blank', 'width=400,height=700');
};
