import React from 'react';
import FundingList from './FundingList';
import styles from './styles.module.scss';

const FundingPageBlock = () => {
    return (
        <div className={styles['FundingBlock']}>
            <h5
                className={`${styles['FundingBlock__routeTitle']} pageRouteTitle`}
            >
                Главная &gt; Funding
            </h5>
            <FundingList />
        </div>
    );
};

export default FundingPageBlock;
