import React from 'react';

import { crossIcon, eyeIcon, reportFakeIcon } from '../../../../../assets/svg';
import {
    getTimeDifference,
    setDateText,
} from '../../../../../utils/functions/date';
import Svg from '../../../../layout/Svg/Svg';

import { trackerTypes } from '../../../../../constants';
import styles from './styles.module.scss';

const TrackerPageListItem = ({
    token,
    exchange,
    blockchain,
    current_price,
    last_price,
    timestamp,
    change,
    link,
    type,
    Item,
    onSendReportMessage,
    onAddToHidden,
    onAddToBlackList,
}) => {
    const spotedText = getTimeDifference(timestamp);
    const isUp = current_price >= last_price;

    return (
        <div className={styles['trackerListItem']}>
            <div className={styles['trackerListItem__header']}>
                <h5 className={styles['trackerListItem__title']}>
                    {token?.toUpperCase().replace('USDT', '')}
                </h5>
                <div className={styles['trackerListItem__headerBtns']}>
                    <>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={() => onSendReportMessage(Item)}
                        >
                            <Svg
                                id={reportFakeIcon}
                                className={
                                    styles['trackerListItem__reportIcon']
                                }
                            />
                        </button>
                        <button
                            onClick={onAddToHidden}
                            className={styles['trackerListItem__hiderBtn']}
                        >
                            <Svg
                                className={styles['trackerListItem__hideIcon']}
                                id={eyeIcon}
                            />
                        </button>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={onAddToBlackList}
                        >
                            <Svg
                                className={styles['trackerListItem__crossIcon']}
                                id={crossIcon}
                            />
                        </button>
                    </>
                </div>
            </div>

            <div
                className={`${styles['trackerListItem__resultBlock']} ${isUp ? styles['trackerListItem__resultBlock_up'] : styles['trackerListItem__resultBlock_fall']}`}
            >
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {type === trackerTypes.CEX ? 'Exchange' : 'Blockchain'}
                    </span>
                    {type === trackerTypes.CEX ? exchange : blockchain}
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {isUp ? 'Up' : 'Fall'} in{' '}
                    </span>
                    {change.toFixed(2)}%
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        Current price:{' '}
                    </span>
                    {current_price}
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        Previous price:{' '}
                    </span>
                    {last_price}
                </p>
                <p>
                    <p className={styles['trackerListItem__resultBlockText']}>
                        <a
                            className={
                                styles['trackerListItem__resultBlockLinkText']
                            }
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {type === trackerTypes.CEX
                                ? 'Chart'
                                : 'Dex Screener'}
                        </a>
                    </p>
                </p>
            </div>

            <div className={styles['trackerListItem__footer']}>
                <p className={styles['trackerListItem__footerText']}>
                    Spotted: {setDateText(spotedText)}
                </p>
            </div>
        </div>
    );
};

export default TrackerPageListItem;
