// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING_START = 'LOGIN_LOADING_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_LOADING_START = 'SIGNUP_LOADING_START';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_LOADING_START = 'FORGOT_PASSWORD_LOADING_START';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_LOADING_START = 'RESET_PASSWORD_LOADING_START';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const EDIT_USER_DATA_SUCCESS = 'EDIT_USER_DATA_SUCCESS';
export const EDIT_USER_DATA_LOADING_START = 'EDIT_USER_DATA_LOADING_START';
export const EDIT_USER_DATA_ERROR = 'EDIT_USER_DATA_ERROR';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_LOADING_START = 'CHANGE_PASSWORD_LOADING_START';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_LOADING_START = 'GET_USER_LOADING_START';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

// news
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_LOADING_START = 'GET_NEWS_LOADING_START';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_LOADING_START = 'CREATE_NEWS_LOADING_START';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';

export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_LOADING_START = 'EDIT_NEWS_LOADING_START';
export const EDIT_NEWS_ERROR = 'EDIT_NEWS_ERROR';

export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_LOADING_START = 'DELETE_NEWS_LOADING_START';
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR';

// arbitrage
export const GET_ARBITRAGE_SUCCESS = 'GET_ARBITRAGE_SUCCESS';
export const GET_ARBITRAGE_LOADING_START = 'GET_ARBITRAGE_LOADING_START';
export const GET_ARBITRAGE_ERROR = 'GET_ARBITRAGE_ERROR';

// cex outflows
export const GET_CEX_OUTFLOWS_SUCCESS = 'GET_CEX_OUTFLOWS_SUCCESS';
export const GET_CEX_OUTFLOWS_LOADING_START = 'GET_CEX_OUTFLOWS_LOADING_START';
export const GET_CEX_OUTFLOWS_ERROR = 'GET_CEX_OUTFLOWS_ERROR';

// trackers
export const GET_CEX_TRACKER_SUCCESS = 'GET_CEX_TRACKER_SUCCESS';
export const GET_CEX_TRACKER_LOADING_START = 'GET_CEX_TRACKER_LOADING_START';
export const GET_CEX_TRACKER_ERROR = 'GET_CEX_TRACKER_ERROR';

export const GET_DEX_TRACKER_SUCCESS = 'GET_DEX_TRACKER_SUCCESS';
export const GET_DEX_TRACKER_LOADING_START = 'GET_DEX_TRACKER_LOADING_START';
export const GET_DEX_TRACKER_ERROR = 'GET_DEX_TRACKER_ERROR';

//filters
export const SET_FILTERS = 'SET_FILTERS';
export const CHANGE_FILTERS_SUCCESS = 'CHANGE_FILTERS_SUCCESS';
export const CHANGE_FILTERS_LOADING_START = 'CHANGE_FILTERS_LOADING_START';
export const CHANGE_FILTERS_ERROR = 'CHANGE_FILTERS_ERROR';
export const TOGGLE_AUTO_REFRESH = 'TOGGLE_AUTO_REFRESH';
export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH';

//notifications
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const SET_TG_NOTIFICATIONS = 'SET_TG_NOTIFICATIONS';
export const CHANGE_TG_NOTIFICATIONS_SUCCESS =
    'CHANGE_TG_NOTIFICATIONS_SUCCESS';

export const SET_WEB_NOTIFICATIONS = 'SET_WEB_NOTIFICATIONS';
export const CHANGE_WEB_NOTIFICATIONS_SUCCESS =
    'CHANGE_WEB_NOTIFICATIONS_SUCCESS';
export const CHANGE_NOTIFICATIONS_LOADING_START =
    'CHANGE_NOTIFICATIONS_LOADING_START';
export const CHANGE_NOTIFICATIONS_ERROR = 'CHANGE_NOTIFICATIONS_ERROR';

export const SET_PUSH_NOTIFICATIONS_DATA = 'SET_PUSH_NOTIFICATIONS_DATA';
export const TOGGLE_NOTIFICATIONS_LOADING_START =
    'TOGGLE_NOTIFICATIONS_LOADING_START';
export const TOGGLE_NOTIFICATIONS_SUCCESS = 'TOGGLE_NOTIFICATIONS_SUCCESS';
export const TOGGLE_NOTIFICATIONS_ERROR = 'TOGGLE_NOTIFICATIONS_ERROR';

// funding
export const GET_FUNDING_SUCCESS = 'GET_FUNDING_SUCCESS';
export const GET_FUNDING_LOADING_START = 'GET_FUNDING_LOADING_START';
export const GET_FUNDING_ERROR = 'GET_FUNDING_ERROR';
