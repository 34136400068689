import {
    GET_FUNDING_ERROR,
    GET_FUNDING_LOADING_START,
    GET_FUNDING_SUCCESS,
} from '../types';
import { fetchRequest, getFundingUrl } from './fetchTools';

export const getFunding = (payload) => (dispatch) => {
    dispatch({ type: GET_FUNDING_SUCCESS, payload });
};

export const requestFunding = () => async (dispatch) => {
    dispatch({ type: GET_FUNDING_LOADING_START });
    try {
        const { Data: data } = await fetchRequest(getFundingUrl);
        dispatch(getFunding(data || []));
    } catch (err) {
        console.log(err);
        dispatch({ type: GET_FUNDING_ERROR });
    }
};
