import { getToken } from 'firebase/messaging';
import {
    getLSItem,
    removeLSItem,
    setLSItem,
} from '../../utils/functions/localStorage';
import {
    initializeFirebaseServices,
    requestNotificationPermission,
} from '../../utils/functions/pushNotification';
import { lsProps } from '../../utils/lsProps';
import {
    CHANGE_NOTIFICATIONS_ERROR,
    CHANGE_NOTIFICATIONS_LOADING_START,
    CHANGE_TG_NOTIFICATIONS_SUCCESS,
    CHANGE_WEB_NOTIFICATIONS_SUCCESS,
    TOGGLE_NOTIFICATION,
    TOGGLE_NOTIFICATIONS_ERROR,
    TOGGLE_NOTIFICATIONS_LOADING_START,
    TOGGLE_NOTIFICATIONS_SUCCESS,
} from '../types';
import {
    authConfig,
    changeNotificationsUrl,
    fetchRequest,
    sendFirebaseTokenToServer,
} from './fetchTools';

export const toggleNotification =
    (type, section) => async (dispatch, getState) => {
        dispatch({
            type: TOGGLE_NOTIFICATION,
            payload: { type, section },
        });

        dispatch({ type: CHANGE_NOTIFICATIONS_LOADING_START });

        const user = getState().auth.user;

        const reqBody = {
            id: user.id,
            tg_notifications: getState().notifications.tg,
            web_notifications: getState().notifications.web,
        };

        console.log(reqBody);

        try {
            const res = await fetchRequest(
                changeNotificationsUrl,
                'POST',
                reqBody,
                authConfig()
            );

            console.log(res);

            const { tg_notifications, web_notifications } = res.User;

            tg_notifications &&
                type === 'tg' &&
                dispatch({
                    type: CHANGE_TG_NOTIFICATIONS_SUCCESS,
                    payload: tg_notifications,
                });

            web_notifications &&
                type === 'web' &&
                dispatch({
                    type: CHANGE_WEB_NOTIFICATIONS_SUCCESS,
                    payload: web_notifications,
                });
        } catch (error) {
            console.log(error);
            dispatch({ type: CHANGE_NOTIFICATIONS_ERROR, error });
        }
    };

export const togglePush = () => async (dispatch) => {
    dispatch({ type: TOGGLE_NOTIFICATIONS_LOADING_START });

    const vapidKey = process.env.REACT_APP_VAPID_KEY;
    const apiBaseUrl = process.env.REACT_APP_API_URL;

    try {
        const isNotificationsActive = getLSItem(lsProps.usePushNot, true);
        const messaging = initializeFirebaseServices();

        if (isNotificationsActive) {
            const currentToken = await getToken(messaging, { vapidKey });
            if (currentToken) {
                console.log('Token:', currentToken);
                await sendFirebaseTokenToServer(
                    `${apiBaseUrl}/deleteToken`,
                    currentToken
                );

                removeLSItem(lsProps.pushToken);

                dispatch({
                    type: TOGGLE_NOTIFICATIONS_SUCCESS,
                    paylod: { token: null, isPushEnabled: false },
                });

                setLSItem(lsProps.usePushNot, false);
            } else {
                console.log('No registration token available.');
                dispatch({
                    type: TOGGLE_NOTIFICATIONS_ERROR,
                    payload: 'No registration token available.',
                });
            }
        } else {
            const permissionGranted = await requestNotificationPermission();
            if (permissionGranted) {
                const currentToken = await getToken(messaging, { vapidKey });
                if (currentToken) {
                    console.log('Token:', currentToken);
                    await sendFirebaseTokenToServer(
                        `${apiBaseUrl}/addToken`,
                        currentToken
                    );

                    setLSItem(lsProps.pushToken, currentToken);

                    dispatch({
                        type: TOGGLE_NOTIFICATIONS_SUCCESS,
                        payload: { token: currentToken, isPushEnabled: true },
                    });

                    setLSItem(lsProps.usePushNot, true);
                } else {
                    console.log('No registration token available.');
                    dispatch({
                        type: TOGGLE_NOTIFICATIONS_ERROR,
                        payload: 'No registration token available.',
                    });
                }
            }
        }
    } catch (error) {
        console.log('Error:', error);
        dispatch({ type: TOGGLE_NOTIFICATIONS_ERROR, payload: error.message });
    }
};
