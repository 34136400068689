import React from 'react';
import CEXOutflowsList from './CEXOutflowsList';
import styles from './styles.module.scss';

const CEXOutflowsPageBlock = () => {
    return (
        <div className={styles['CEXOutflowsBlock']}>
            <h5
                className={`${styles['CEXOutflowsBlock__routeTitle']} pageRouteTitle`}
            >
                Главная &gt; CEX Outflows
            </h5>
            <CEXOutflowsList />
        </div>
    );
};

export default CEXOutflowsPageBlock;
