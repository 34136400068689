import React from 'react';

import { useLocation } from 'react-router-dom';
import styles from './ArbitragePageHeader.module.scss';

const ArbitragePageHeader = ({ arbitrageType }) => {
    const { hash } = useLocation();

    return (
        <div className={styles['arbitrageHeader']}>
            <h5 className={'pageRouteTitle'}>
                Главная &gt; {arbitrageType} &gt;
                {hash
                    ? ` ${hash.slice(1).replace('-', ' - ').toUpperCase()}`
                    : ''}
            </h5>
        </div>
    );
};

export default ArbitragePageHeader;
