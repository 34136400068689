import React from 'react';

import { useLocation } from 'react-router-dom';

const TrackerPageHeader = () => {
    const { hash } = useLocation();

    return (
        <h5 className={`pageRouteTitle`}>
            Главная &gt; Tracker &gt;{' '}
            {hash ? ` ${hash.slice(1).toUpperCase()}` : ''}
        </h5>
    );
};

export default TrackerPageHeader;
