import React from 'react';

import NewPortalProvider from '../../../../providers/NewPortalProvider';
import TransitionProvider from '../../../../providers/TransitionProvider';
import Backdrop from '../../../layout/Backdrop/Backdrop';
import SideBarFooter from './SideBarFooter';
import SideBarLinks from './SideBarLinks';

import styles from './styles.module.scss';

function SideBar({ burgerOpened, onCloseBurger, isMobile }) {
    return (
        <>
            <Backdrop
                inProp={burgerOpened}
                onClose={onCloseBurger}
                enableScroll={true}
                className={styles['sideBar__backdrop']}
            />
            <NewPortalProvider>
                <TransitionProvider
                    inProp={burgerOpened}
                    style={'left'}
                    className={styles['sideBar']}
                >
                    <SideBarLinks
                        isMobile={isMobile}
                        onCloseBurger={onCloseBurger}
                    />
                    <SideBarFooter
                        isMobile={isMobile}
                        onCloseBurger={onCloseBurger}
                    />
                </TransitionProvider>
            </NewPortalProvider>
        </>
    );
}

export default SideBar;
