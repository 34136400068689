import React from 'react';
import SubscriptionBlock from '../../global/SubscriptionBlock/SubscriptionBlock';

import styles from './SubscribtionPageContainer.module.scss';

function SubscribtionPageContainer() {
    return (
        <div className={styles['subscribtionContainer']}>
            <h5
                className={`${styles['subscribtionContainer__title']} pageRouteTitle`}
            >
                Главная &gt; Subscription
            </h5>
            <SubscriptionBlock />
        </div>
    );
}

export default SubscribtionPageContainer;
