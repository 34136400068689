import React, { memo } from 'react';

import { crossIcon } from '../../../../assets/svg';
import NewPortalProvider from '../../../../providers/NewPortalProvider';
import TransitionProvider from '../../../../providers/TransitionProvider';
import Backdrop from '../../../layout/Backdrop/Backdrop';
import CrossBtn from '../../../layout/CrossBtn/CrossBtn';
import Svg from '../../../layout/Svg/Svg';

import styles from './styles.module.scss';

const NonVisibleItemsModal = memo(
    ({ isOpened, onClose, title, items, onRemoveItem }) => {
        return (
            <>
                <Backdrop
                    inProp={isOpened}
                    onClose={onClose}
                    highZIndex={true}
                />
                <NewPortalProvider>
                    <TransitionProvider
                        className={styles['nonVisibleItemsModal']}
                        style={'opacity'}
                        inProp={isOpened}
                    >
                        <CrossBtn onClick={onClose} />
                        <h4 className={styles['nonVisibleItemsModal__title']}>
                            {title}
                        </h4>
                        <div
                            className={
                                styles['nonVisibleItemsModal__container']
                            }
                        >
                            {items.map((item) => (
                                <div
                                    key={item}
                                    className={
                                        styles['nonVisibleItemsModal__item']
                                    }
                                >
                                    <p
                                        className={
                                            styles[
                                                'nonVisibleItemsModal__itemText'
                                            ]
                                        }
                                    >
                                        {item}
                                    </p>
                                    <button
                                        className={
                                            styles[
                                                'nonVisibleItemsModal__itemRemoveBtn'
                                            ]
                                        }
                                        onClick={() => onRemoveItem(item)}
                                    >
                                        <Svg
                                            className={
                                                styles[
                                                    'nonVisibleItemsModal__itemRemoveIcon'
                                                ]
                                            }
                                            id={crossIcon}
                                        />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </TransitionProvider>
                </NewPortalProvider>
            </>
        );
    }
);

export default NonVisibleItemsModal;
