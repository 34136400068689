import React from 'react';
import MainInput from '../../../../layout/MainInput/MainInput';
import Svg from '../../../../layout/Svg/Svg';
import CheckboxFilter from './CkeckboxFilter';
import SelectFilter from './SelectFilter';
import styles from './styles.module.scss';

const FilterModalItem = ({
    filter,
    formData,
    onToggleCheckBox,
    onToggleBlockchainSelect,
    onToggleSortSelect,
    onChooseSortSelect,
    onChooseBlockchainSelect,
    onInputChange,
    chosenBlockchain,
    chosenSort,
    isBlockchainSelectOpened,
    isSortSelectOpened,
}) => {
    if (!filter) return <></>;

    const {
        filterType,
        filterKey,
        filterLabel,
        filterOptions,
        filterSubLabel,
        filterIcon,
    } = filter;

    return (
        <div className={styles['item']}>
            {filterLabel && (
                <>
                    <h6
                        className={`${styles.title} ${filterSubLabel && styles.title__withSubTitle}`}
                    >
                        {filterIcon && <Svg id={filterIcon} />}
                        {filterLabel}
                    </h6>
                    {filterSubLabel && (
                        <span className={styles.inputLabelText}>
                            {filterSubLabel}
                        </span>
                    )}
                </>
            )}
            {(filterKey === 'exchanges' || filterKey === 'blockchains') && (
                <CheckboxFilter
                    options={filterOptions}
                    formData={formData}
                    onToggleCheckBox={onToggleCheckBox}
                    name={filterKey}
                />
            )}
            {filterKey === 'blockchain' && (
                <SelectFilter
                    isSelectOpened={isBlockchainSelectOpened}
                    options={filterOptions}
                    onChooseSelect={onChooseBlockchainSelect}
                    onToggleSelect={onToggleBlockchainSelect}
                    chosenOption={chosenBlockchain}
                />
            )}
            {filterKey === 'sort' && (
                <SelectFilter
                    isSelectOpened={isSortSelectOpened}
                    options={filterOptions}
                    onChooseSelect={onChooseSortSelect}
                    onToggleSelect={onToggleSortSelect}
                    chosenOption={chosenSort}
                    name={filterKey}
                />
            )}
            {filterType === 'input' && (
                <MainInput
                    onChange={onInputChange}
                    type="number"
                    className={styles['input']}
                    value={formData[filterKey]}
                    name={filterKey}
                />
            )}
        </div>
    );
};

export default FilterModalItem;
