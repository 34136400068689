import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { successIcon } from '../../../assets/svg';
import { signUpPagePath } from '../../../router/path';
import MainBtn from '../../layout/MainBtn/MainBtn';
import Svg from '../../layout/Svg/Svg';
import SubscriptionBlockModal from './SubscriptionBlockModal/SubscriptionBlockModal';

import styles from './SubscriptionBlock.module.scss';

function SubscriptionBlock() {
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const [selectedSubscribeType, setSelectedSubscribeType] = useState(null);

    const onSelectSubscribe = (type) => {
        token ? setSelectedSubscribeType(type) : navigate(signUpPagePath);
    };
    const closeModal = () => setSelectedSubscribeType(null);

    return (
        <>
            <div className={styles['subscriptionBlock']}>
                <div className={styles['subscriptionBlock__box']}>
                    <h5 className={styles['subscriptionBlock__boxTitle']}>
                        Arbitrage free
                    </h5>
                    <p
                        className={`${`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`} ${styles['subscriptionBlock__boxDescriptionText']}`}
                    >
                        Ограниченные возможности, за счёт которых, ты сможешь
                        познакомиться с CEX / CEX направлением крипто-арбитража.
                    </p>
                    <p className={styles['subscriptionBlock__boxPriceText']}>
                        $0 / ∞ мес.
                    </p>
                    <MainBtn
                        className={styles['subscriptionBlock__btn']}
                        onClick={() => onSelectSubscribe('free')}
                    >
                        Выбрать
                    </MainBtn>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Free News
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Free Arbitrage
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles[
                                        'subscriptionBlock__boxOptionIconFake'
                                    ]
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxTextFake']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Фундаментальное
                            </p>
                        </div>
                    </div>
                    {/* <div className={styles["subscriptionBlock__boxOptionBlock"]}>
                        <div className={styles["subscriptionBlock__boxOption"]}>
                            <Svg id={successIcon} className={styles["subscriptionBlock__boxOptionIcon"]} />
                            <p className={`${styles["subscriptionBlock__boxText"]} ${styles["subscriptionBlock__boxOptionsText"]}`}> </p>
                        </div>
                    </div> */}
                </div>

                <div className={styles['subscriptionBlock__box']}>
                    <h5 className={styles['subscriptionBlock__boxTitle']}>
                        Arbitrage start
                    </h5>
                    <p
                        className={`${`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`} ${styles['subscriptionBlock__boxDescriptionText']}`}
                    >
                        Безграничные возможности, за счёт которых, ты сможешь
                        обуздать CEX / DEX направления крипто-арбитража.
                    </p>
                    <p className={styles['subscriptionBlock__boxPriceText']}>
                        $400 / 1 мес.
                    </p>
                    <MainBtn
                        className={styles['subscriptionBlock__btn']}
                        onClick={() => onSelectSubscribe('free')}
                    >
                        Выбрать
                    </MainBtn>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — CEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                DEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Arbitrage News (Разбор актуальных новостей +Live
                                торговля)
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Фундаментальное обучение
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles[
                                        'subscriptionBlock__boxOptionIconFake'
                                    ]
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxTextFake']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Фундаментальное
                            </p>
                        </div>
                    </div>
                </div>

                <div className={styles['subscriptionBlock__box']}>
                    <h5 className={styles['subscriptionBlock__boxTitle']}>
                        Arbitrage base
                    </h5>
                    <p
                        className={`${`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`} ${styles['subscriptionBlock__boxDescriptionText']}`}
                    >
                        Безграничные возможности, за счёт которых, ты сможешь
                        обуздать CEX / DEX направления крипто-арбитража.
                    </p>
                    <p className={styles['subscriptionBlock__boxPriceText']}>
                        $1100 / 3 мес.
                    </p>
                    <MainBtn
                        className={styles['subscriptionBlock__btn']}
                        onClick={() => onSelectSubscribe('free')}
                    >
                        Выбрать
                    </MainBtn>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — CEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                DEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Arbitrage News (Разбор актуальных новостей +Live
                                торговля)
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Фундаментальное обучение + приватная группа в
                                telegram
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Дальнейший доступ к новым Инструментам/ Функциям
                                1whale.io
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Поддержка 24/7
                            </p>
                        </div>
                    </div>
                </div>

                <div className={styles['subscriptionBlock__box']}>
                    <h5 className={styles['subscriptionBlock__boxTitle']}>
                        Arbitrage ultra
                    </h5>
                    <p
                        className={`${`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`} ${styles['subscriptionBlock__boxDescriptionText']}`}
                    >
                        Безграничные возможности, за счёт которых, ты сможешь
                        обуздать CEX / DEX направления крипто-арбитража.
                    </p>
                    <p className={styles['subscriptionBlock__boxPriceText']}>
                        $2000 / 6 мес.
                    </p>
                    <MainBtn
                        className={styles['subscriptionBlock__btn']}
                        onClick={() => onSelectSubscribe('free')}
                    >
                        Выбрать
                    </MainBtn>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — CEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                CEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                DEX — DEX Arbitrage Scanner
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Arbitrage News (Разбор актуальных новостей +Live
                                торговля)
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Фундаментальное обучение + приватная группа в
                                telegram
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Дальнейший доступ к новым Инструментам/ Функциям
                                1whale.io
                            </p>
                        </div>
                    </div>
                    <div
                        className={styles['subscriptionBlock__boxOptionBlock']}
                    >
                        <div className={styles['subscriptionBlock__boxOption']}>
                            <Svg
                                id={successIcon}
                                className={
                                    styles['subscriptionBlock__boxOptionIcon']
                                }
                            />
                            <p
                                className={`${styles['subscriptionBlock__boxText']} ${styles['subscriptionBlock__boxOptionsText']}`}
                            >
                                Поддержка 24/7
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {selectedSubscribeType ? (
                <SubscriptionBlockModal
                    onClose={closeModal}
                    show={selectedSubscribeType}
                />
            ) : null}
        </>
    );
}

export default SubscriptionBlock;
