import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { navLogoImage } from '../../../../../assets/images';
import { arrowDownIcon, arrowIcon } from '../../../../../assets/svg';
import { subscriptionTypes } from '../../../../../constants';
import TransitionProvider from '../../../../../providers/TransitionProvider';
import { mainPagePath } from '../../../../../router/path';
import Svg from '../../../../layout/Svg/Svg';
import { navLinks } from '../config';

import styles from './styles.module.scss';

const SideBarLinks = ({ onCloseBurger, isMobile }) => {
    const location = useLocation();

    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);

    const { pathname, hash } = location;

    const setActiveNavLinkClass = (defaultClass, activeClass) => {
        return ({ isActive }) =>
            isActive ? `${defaultClass} ${activeClass}` : defaultClass;
    };
    const activeNavLinks = setActiveNavLinkClass(
        styles['sideBarLinks__link'],
        styles['sideBarLinks__link_active']
    );

    const allowedLinks = navLinks.filter((item) => token || !item.isPrivate);

    return (
        <>
            <button
                className={styles['sideBarLinks__closeBtn']}
                onClick={onCloseBurger}
            >
                <Svg
                    className={styles['sideBarLinks__closeIcon']}
                    id={arrowIcon}
                />
            </button>
            <Link to={mainPagePath} onClick={isMobile ? onCloseBurger : null}>
                <img
                    src={navLogoImage}
                    alt="Logo"
                    className={styles['sideBarLinks__logoImage']}
                />
            </Link>
            <div
                className={`${styles['sideBarLinks__container']} scrollbarDef`}
            >
                {allowedLinks.map(
                    ({ title, icon, path, adminPath, sublinks }, index) => {
                        const linkPath =
                            user?.role === 'admin' && adminPath
                                ? adminPath
                                : path;
                        const isAuthenticated =
                            token &&
                            user.subscription === subscriptionTypes.arb;
                        const filteredSubLinks = sublinks?.filter(
                            (item) =>
                                user?.role === 'admin' ||
                                (item.isPrivate && isAuthenticated) ||
                                !item.isPrivate
                        );

                        const onClickLinks = (isNavLink) => {
                            if (
                                isMobile &&
                                (!isNavLink ||
                                    (isNavLink && !sublinks) ||
                                    filteredSubLinks.length === 1)
                            )
                                onCloseBurger();
                        };

                        return (
                            <Fragment key={index}>
                                {index === navLinks.length - 1 ? (
                                    <b
                                        className={styles['sideBarLinks__line']}
                                    />
                                ) : null}
                                <NavLink
                                    onClick={() => onClickLinks(true)}
                                    to={`${linkPath}${filteredSubLinks ? '#' + filteredSubLinks[0].href : ''}`}
                                    className={activeNavLinks}
                                >
                                    <Svg
                                        className={
                                            styles['sideBarLinks__linkIcon']
                                        }
                                        id={icon}
                                    />
                                    <span
                                        className={
                                            styles['sideBarLinks__linkText']
                                        }
                                    >
                                        {title}
                                    </span>
                                    {sublinks ? (
                                        <Svg
                                            id={arrowDownIcon}
                                            className={
                                                styles[
                                                    'sideBarLinks__linkArrowIcon'
                                                ]
                                            }
                                        />
                                    ) : null}
                                </NavLink>
                                {sublinks ? (
                                    <TransitionProvider
                                        duration={300}
                                        inProp={pathname.includes(linkPath)}
                                        style={'height'}
                                        height={'300px'}
                                        className={
                                            styles['sideBarLinks__acardeon']
                                        }
                                    >
                                        {filteredSubLinks.map((item, index) => (
                                            <Link
                                                key={index}
                                                onClick={() => onClickLinks()}
                                                to={`${linkPath}#${item.href}`}
                                                className={`${styles['sideBarLinks__acardeonItem']} ${hash.includes(item.href) ? styles['sideBarLinks__acardeonItem_active'] : ''}`}
                                            >
                                                <span
                                                    className={
                                                        styles[
                                                            'sideBarLinks__acardeonItemLine'
                                                        ]
                                                    }
                                                ></span>
                                                <span>{item.title}</span>
                                            </Link>
                                        ))}
                                    </TransitionProvider>
                                ) : null}
                            </Fragment>
                        );
                    }
                )}
            </div>
        </>
    );
};

export default SideBarLinks;
