import AdminLoginPage from '../pages/Admin/AdminLoginPage/AdminLoginPage';
import AdminNewsPage from '../pages/Admin/AdminNewsPage/AdminNewsPage';
import ArbitragePage from '../pages/ArbitragePage/ArbitragePage';
import CEXOutflowsPage from '../pages/CEXOutflowsPage';
import ClosedArbitragePage from '../pages/ClosedArbitragePage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import FundingPage from '../pages/FundingPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import MainPage from '../pages/MainPage/MainPage';
import NewsPage from '../pages/NewsPage/NewsPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import SubscribtionPage from '../pages/SubscribtionPage/SubscribtionPage';
import SupportPage from '../pages/SupportPage/SupportPage';
import TrackerPage from '../pages/TrackerPage';
import PrivateRoute from './PrivateRoute';

export const mainPagePath = '/';
export const arbitragePagePath = '/arbitrage';
export const closedArbitragePagePath = '/closedArbitrage';
export const fundingPagePath = '/funding';
export const trackerPagePath = '/tracker';
export const dexTrackerPagePath = '/dexTracker';
export const cexOutflowPagePath = '/cexOutflows';

export const resetPasswordPagePath = '/resetPassword';
export const forgotPasswordPagePath = '/forgotPassword';
export const loginPagePath = '/login';
export const newsPagePath = '/news';
export const settingsPagePath = '/settings';
export const signUpPagePath = '/signUp';
export const subscribtionPagePath = '/subscribtion';
export const supportPagePath = '/support';

// админ
export const adminLoginPagePath = '/admin/login';
export const adminNewsPagePath = '/admin/news';

export const routes = [
    {
        path: mainPagePath,
        component: <MainPage />,
    },
    {
        path: arbitragePagePath,
        component: <PrivateRoute element={<ArbitragePage />} />,
    },
    {
        path: closedArbitragePagePath,
        component: <PrivateRoute element={<ClosedArbitragePage />} />,
    },
    {
        path: trackerPagePath,
        component: <PrivateRoute element={<TrackerPage />} />,
    },
    {
        path: cexOutflowPagePath,
        component: <PrivateRoute element={<CEXOutflowsPage />} />,
    },
    {
        path: fundingPagePath,
        component: <PrivateRoute element={<FundingPage />} />,
    },
    {
        path: resetPasswordPagePath,
        component: (
            <PrivateRoute element={<ResetPasswordPage />} noAuth={true} />
        ),
    },
    {
        path: forgotPasswordPagePath,
        component: (
            <PrivateRoute element={<ForgotPasswordPage />} noAuth={true} />
        ),
    },
    {
        path: loginPagePath,
        component: <PrivateRoute element={<LoginPage />} noAuth={true} />,
    },
    {
        path: newsPagePath,
        component: <PrivateRoute element={<NewsPage />} />,
    },
    {
        path: settingsPagePath,
        component: <PrivateRoute element={<SettingsPage />} />,
    },
    {
        path: signUpPagePath,
        component: <PrivateRoute element={<SignUpPage />} noAuth={true} />,
    },
    {
        path: subscribtionPagePath,
        component: <SubscribtionPage />,
    },
    {
        path: supportPagePath,
        component: <SupportPage />,
    },

    // админ
    {
        path: adminLoginPagePath,
        component: <PrivateRoute element={<AdminLoginPage />} noAuth={true} />,
    },
    {
        path: adminNewsPagePath,
        component: <PrivateRoute element={<AdminNewsPage />} isAdmin={true} />,
    },
    {
        path: '*',
        component: <ErrorPage />,
    },
];
