import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logOutIcon } from '../../../../../assets/svg';
import { logOut } from '../../../../../redux/action/auth';
import { loginPagePath } from '../../../../../router/path';
import Svg from '../../../../layout/Svg/Svg';
import { socials } from '../config';

import styles from './styles.module.scss';

const SideBarFooter = ({ isMobile, onCloseBurger }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const onToggleAuth = () => {
        const clb = () => {
            navigate(loginPagePath);
            if (isMobile) onCloseBurger();
        };
        if (token) {
            dispatch(logOut(clb));
        } else {
            navigate(loginPagePath);
            if (isMobile) onCloseBurger();
        }
    };

    return (
        <div className={styles['sideBarFooter']}>
            <nav className={styles['sideBarFooter__socialIconsBlock']}>
                {socials.map((item, index) => (
                    <a
                        key={index}
                        href={item.link}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={styles['sideBarFooter__socialBtn']}
                    >
                        <Svg
                            className={styles['sideBarFooter__socialIcon']}
                            id={item.iconId}
                        />
                    </a>
                ))}
            </nav>
            <button
                className={styles['sideBarFooter__logoutBtn']}
                onClick={onToggleAuth}
            >
                <Svg
                    className={styles['sideBarFooter__logoutIcon']}
                    id={logOutIcon}
                />
                <span className={styles['sideBarFooter__logoutText']}>
                    {token ? 'Logout' : 'Log In'}
                </span>
            </button>
        </div>
    );
};

export default SideBarFooter;
