import {
    GET_CEX_TRACKER_ERROR,
    GET_CEX_TRACKER_LOADING_START,
    GET_CEX_TRACKER_SUCCESS,
    GET_DEX_TRACKER_ERROR,
    GET_DEX_TRACKER_LOADING_START,
    GET_DEX_TRACKER_SUCCESS,
} from '../types';
import { fetchRequest, getCEXTrackerUrl, getDEXTrackerUrl } from './fetchTools';

export const getCexTracker = (payload) => (dispatch) => {
    dispatch({ type: GET_CEX_TRACKER_SUCCESS, payload });
};

export const requestCexTracker = () => async (dispatch) => {
    dispatch({ type: GET_CEX_TRACKER_LOADING_START });
    try {
        const { Data: data } = await fetchRequest(getCEXTrackerUrl);
        dispatch(getCexTracker(data || []));
    } catch (err) {
        console.log(err);
        dispatch({ type: GET_CEX_TRACKER_ERROR });
    }
};

export const getDexTracker = (payload) => (dispatch) => {
    dispatch({ type: GET_DEX_TRACKER_SUCCESS, payload });
};

export const requestDexTracker = () => async (dispatch) => {
    dispatch({ type: GET_DEX_TRACKER_LOADING_START });
    try {
        const { Data: data } = await fetchRequest(getDEXTrackerUrl);
        dispatch(getDexTracker(data || []));
    } catch (err) {
        console.log(err);
        dispatch({ type: GET_DEX_TRACKER_ERROR });
    }
};
