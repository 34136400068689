import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { bscIcon, ethIcon, solIcon, walletIcon } from '../../../../assets/svg';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const BalanceBlock = () => {
    const user = useSelector((state) => state.auth.user);

    const chains = [
        {
            chain: 'Solana',
            icon: solIcon,
            balance: user?.solBalance || 0,
            token: 'USDT',
            wallet: user?.solWallet || 'Не привязан',
        },
        {
            chain: 'Ethereum',
            icon: ethIcon,
            balance: user?.ethBalance || 0,
            token: 'ETH',
            wallet: user?.ethWallet || 'Не привязан',
        },
        {
            chain: 'BSC',
            icon: bscIcon,
            balance: user?.bscBalance || 0,
            token: 'BNB',
            wallet: user?.bscWallet || 'Не привязан',
        },
    ];
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const handleCopy = (wallet) => {
        navigator.clipboard
            .writeText(wallet)
            .then(() => {
                setIsTooltipVisible(true); // Показать подсказку
                setTimeout(() => {
                    setIsTooltipVisible(false); // Скрыть подсказку через 3 секунды
                }, 3000);
            })
            .catch((err) => {
                console.error('Ошибка при копировании текста: ', err);
            });
    };

    return (
        <div className={styles['balanceBlock']}>
            <div className={styles['balanceBlock__list']}>
                {chains.map((chain, index) => (
                    <div
                        key={index}
                        className={styles['balanceBlock__listItem']}
                    >
                        <div className={styles['balanceBlock__balance']}>
                            <Svg
                                className={styles['balanceBlock__chainIcon']}
                                id={chain.icon}
                            />
                            <p>
                                {chain.chain}: {chain.balance} {chain.token}
                            </p>
                        </div>
                        <div className={styles['balanceBlock__balance']}>
                            <Svg
                                className={styles['balanceBlock__walletIcon']}
                                id={walletIcon}
                            />
                            <span onClick={() => handleCopy(chain.wallet)}>
                                {chain.wallet}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            {isTooltipVisible && (
                <div className={styles['copyTooltip']}>Адрес скопирован!</div>
            )}
        </div>
    );
};

export default BalanceBlock;
